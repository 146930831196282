import { Helmet } from 'react-helmet';
import React from 'react';
import { graphql } from 'gatsby';

import AboutTeaser from '../../components/about-teaser';
import Constraint from '../../components/constraint';
import NewsList from '../../components/NewsList';
import PublicationsTeaser from '../../components/publications-teaser';
import FindingsTeaser from '../../components/findings-teaser';
import TwitterTimeline from '../../components/twitter-timeline';
import withLayout from '../../components/with-layout';
import LogoGrid from '../../components/LogoGrid';
import Partner from '../../components/partner';
import Richtext from '../../components/richtext';
import { getImage } from 'gatsby-plugin-image';
import Figure from '../../components/figure';

const Page = ({
  data: {
    page: {
      title,
      acf: { content: blocks },
    },
  },
}) => (
  <>
    <Helmet title={title} />

    {blocks.map(({ __typename: typename, ...block }, index) => {
      const key = `block-${index}`;

      // eslint-disable-next-line default-case
      switch (typename) {
        case 'WpPage_Acf_Content_FeaturedPublications':
          return <PublicationsTeaser key={key} {...block} />;

        case 'WpPage_Acf_Content_AboutTeaser':
          return <AboutTeaser key={key} {...block} />;

        case 'WpPage_Acf_Content_Findings':
          return <FindingsTeaser key={key} {...block} />;

        case `WpPage_Acf_Content_Logogrid`:
          return <LogoGrid {...block} key={index} />;

        case `WpPage_Acf_Content_Partner`:
          return <Partner {...block} key={index} />;

        case `WpPage_Acf_Content_Text`:
          return <Richtext content={block.text} key={index} />;

        case `WpPage_Acf_Content_Image`:
          const image = getImage(block?.image?.localFile);
          return image ? (
            <Figure
              altText={block.image.altText}
              image={image}
              caption={block?.image?.caption}
              key={index}
            />
          ) : null;

        case `WpPage_Acf_Content_FeaturedNews`:
          // TODO: https://github.com/gatsbyjs/gatsby-source-wordpress-experimental/issues/311
          /* eslint-disable no-unreachable */
          return null;

          // eslint-disable-next-line no-case-declarations
          const { news, ...props } = block;
          return (
            <Constraint>
              <NewsList
                nodes={news}
                title="Coal Transitions News"
                padding
                {...props}
              />
            </Constraint>
          );
        /* eslint-enable no-unreachable */
      }

      return null;
    })}

    <Constraint>
      <TwitterTimeline
        title="Twitter"
        endpoint="/.netlify/functions/twitter-timeline"
      />
    </Constraint>
  </>
);

export const query = graphql`
  query ($databaseId: Int) {
    page: wpPage(databaseId: { eq: $databaseId }) {
      title
      acf {
        content {
          __typename
          ... on WpPage_Acf_Content_AboutTeaser {
            summary
            title
          }
          ... on WpPage_Acf_Content_FeaturedPublications {
            title
            summary
            image {
              altText
              caption
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 800
                    placeholder: BLURRED
                    layout: CONSTRAINED
                  )
                }
              }
            }
            publications {
              ... on WpPublication {
                ...publicationListItem
              }
            }
          }
          ... on WpPage_Acf_Content_Findings {
            title
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
                }
              }
            }
          }
          ... on WpPage_Acf_Content_Text {
            text
          }
          ... on WpPage_Acf_Content_Image {
            image {
              altText
              caption
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 800, placeholder: BLURRED)
                }
              }
            }
          }
          ... on WpPage_Acf_Content_Logogrid {
            ...LogoGrid
          }
          ... on WpPage_Acf_Content_Partner {
            ...PartnerPage
          }
        }
      }
    }
  }
`;

export default withLayout(Page);
